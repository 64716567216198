import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        username: '',
        app: {
            version: '',
        },
    },
    mutations: {
        set_username (state, username) {
            localStorage.setItem('username', username)
            state.username = username
        }
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        }
    }
})

export default store
