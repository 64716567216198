import Mock from 'mockjs'
import './data/user.js'
import './data/menu'
import './data/project'
import './data/expert'
import './data/settings'

Mock.setup({
	timeout: '100-300',
})

Mock.mock('/api/version', () => {
	return {
		url: 'https://mingde.mriabc.com/download/',
		version: '1.2.4',
	}
})
