import Mock from '../utils'

Mock.api('/api/settings/dict', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 1000,
            records: []
        }
    }
})