import Mock from '../utils'
const Random = Mock.Random

var selectData = {
    xlList: [
        {
            label: '博士',
            value: ''
        },
        {
            label: '硕士',
            value: ''
        },
        {
            label: '本科',
            value: ''
        },
    ],
    zcList: [
        {
            label: '主任医师',
            value: ''
        },
        {
            label: '副主任医师',
            value: ''
        },
        {
            label: '高级工程师',
            value: ''
        },
        {
            label: '工程师',
            value: ''
        },
    ],
    zyList: [
        {
            label: '临床医学专业',
            value: ''
        },
        {
            label: '精神医学专业',
            value: ''
        },
        {
            label: '麻醉学专业',
            value: ''
        },
        {
            label: '医学影像学专业',
            value: ''
        },
        {
            label: '放射医学专业',
            value: ''
        },
        {
            label: '眼视光医学专业',
            value: ''
        },
        {
            label: '妇幼保健医学专业',
            value: ''
        }, {
            label: '精神医学',
            value: ''
        }, {
            label: '口腔医学专业',
            value: ''
        },
    ]
}

// eslint-disable-next-line no-unused-vars
Mock.api('/api/expert/select', 'get', (req, res) => {
    return selectData
})
var urlList = ["27c9864e7cd10cac1d16c21446e416b9.jpeg", "7e12c94c15836ad0b929801e100cbe46.jpeg", "a06c987bdcc90ca93d9ee4a62c84d77f.jpeg",
    "d21842c6e9f1d9aac24b465b2573d270.jpeg", "d588f3293efa508d0d98cd4c26b6c86e.jpeg", "dd836c7afb7b92a48de78a804ee53498.jpeg",
    "6456544138fd1b89e71daba17935fe32.jpeg", "c29596a43a9eb02fad9b2608ae223033.jpeg"
]

const makeProject = () => {
    let res = []
    for (let i = 1; i < 101; i++) {
        res.push({
            id: i.toString(),
            imageUrl: "https://cdn.zhack.com/daqing/expert/" + Random.pick(urlList),
            name: Random.cname(),
            zhuanye: Random.pick(['临床医学专业', '麻醉学专业', '精神医学专业', '医学影像学专业', '放射医学专业', '眼视光医学专业', '妇幼保健医学专业', '精神医学', '口腔医学专业']),
            zhicheng: Random.pick(['主任医师', '副主任医师', '高级工程师', '工程师']),
            xueli: Random.pick(['硕士', '博士']),
            jieshao: "",
        })
    }
    return res
}

let data = makeProject()
let choose = []

// eslint-disable-next-line no-unused-vars
Mock.api('/api/expert/table', 'get', (req, res) => {
    let qs = data
    var sh = req.query
    qs = qs.filter(item => {
        let name = item.name.includes(sh.name)
        let zhuanye = item.zhuanye.includes(sh.zhuanye)
        let zhicheng = item.zhicheng.includes(sh.zhicheng)
        let xueli = item.xueli.includes(sh.xueli)
        if (!sh.name) {
            name = true
        }
        if (!sh.zhuanye) {
            zhuanye = true
        }
        if (!sh.zhicheng) {
            zhicheng = true
        }
        if (!sh.xueli) {
            xueli = true
        }
        return name && zhicheng && zhuanye && xueli
    })
    return {
        total: qs.length,
        data: Mock.pagination(qs, req.query.page || 1, req.query.pageSize || 20),
    }
})

Mock.api('/api/expert/choose', 'get', (req, res) => {
    let qs = choose
    console.log(222, qs)
    return {
        total: qs.length,
        data: Mock.pagination(qs, req.query.page || 1, req.query.pageSize || 20),
    }
})

Mock.api('/api/expert/choose', 'post', (req, res) => {
    let form = JSON.parse(req.body)
    form["id"] = Math.random().toString(36).substring(2);
    choose.unshift(form)
    return {
        type: 'success',
        msg: '保存成功'
    }
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/expert/form', 'post', (req, res) => {
    let form = JSON.parse(req.body)
    const id = form.id
    if (!id || id == undefined) {
        form["id"] = Math.random().toString(36).substring(2);
        data.unshift(form)
    } else {
        const index = data.findIndex(item => item.id === id);
        data[index] = form
    }
    return {
        type: 'success',
        msg: '保存成功'
    }
})


// eslint-disable-next-line no-unused-vars
Mock.api('/api/expert/view', 'get', (req, res) => {
    let qs = data
    const id = req.url.split("/").pop()
    var list = qs.filter((item) => item.id === id)
    if (list.length) {
        list = list[0]
    }
    return {
        data: list
    }
})


// 模拟上传图片接口
Mock.mock('/api/expert/upload', 'post', function (options) {
    // eslint-disable-next-line no-unused-vars
    const file = options.body.get('file') // 获取上传的文件对象
    // 模拟上传成功后返回的数据
    return {
        code: 200,
        message: '上传成功',
        data: {
            url: Random.image('200x100', '#4A7BF7', 'Mock.js')
        }
    }
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/expert/del', 'post', (req, res) => {
    const id = req.url.split("/").pop()
    const index = data.findIndex(item => item.id === id);
    data.splice(index, 1)
    return {
        type: 'success',
        msg: '删除成功'
    }
})
